import React from "react";

export interface TaskStepConfig {
  startRoute?: string;
  steps?: ParentStep[];
}

export interface TaskStep {
  route: string;
  component?: any;
  componentProps?: any;
  includeInSteps?: boolean;   // should this item be included in the steps calculations
  isHidden?: boolean;         // if hidden, route will not be included in general flow, but can be accessed if navigated to directly
  isStart?: boolean;          // is this the starting point for the task flow
  isFinal?: boolean;          // is this a final or completion view
  isError?: boolean;          // is this an error view
  isForm?: boolean;           // is this a form step view
}

export const EmptyTaskStep: TaskStep = {
  route: "",
  component: undefined,
  componentProps: undefined,
  includeInSteps: true,
  isHidden: false,
  isStart: false,
  isFinal: false,
  isError: false,
  isForm: undefined,          // not assumed
};

export interface ParentStep extends TaskStep {
  title?: React.ReactElement|string;
  subtitle?: React.ReactElement|string;
  label?: React.ReactElement|string;
  description?: React.ReactElement|string;
  themeColor?: string;
  showPrevButton?: boolean;
  childSteps?: ChildStep[];
}

export interface ChildStep extends TaskStep {}

