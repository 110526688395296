import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";


// BEWARE: make sure we do not save any 
// sensitive data to persisted state.
export interface PersistedState {
  patientId: string;        
  apptIds: string[];        // all appointment ids
  languageIsoCode: string;  // the preferred display ISO language code that was selected (blank if not selected)
  route: string;            // the last route the user was on
  valid: boolean;           // set to true once the user has persisted state worth restoring
  advDirSelection: string;  // the advanced directive selection (not saved to API)
  deltaKeys: string[];      // delta keys for all true delta values
}

const initialState: PersistedState = {
  patientId: "",
  apptIds: [],
  languageIsoCode: "",
  route: "",
  valid: false,
  advDirSelection: "",
  deltaKeys: []
};


let _persistedStateEnabled: boolean = true;

const _persistedStateKeys = Object.keys(initialState);
const _mergePersistedState = (state: PersistedState, newState: PersistedState): void => {
  // merge the state in place (do not create a new state object)
  // only copy values that belong to our expected state object
  // copy values rather than re-assigning a new state object so that 
  // the original state object bindings remain
  if (newState) {
    _persistedStateKeys.forEach(k => {
      state[k] = newState[k];
    });
  }
};

/**
 * collect persisted data from redux 
 */
 const _collectPersistedAppState = (state: any): PersistedState | undefined => {
  // if the persisted state is not enabled, then do not save state
  // ex: app is still initializing or app has finalized
  if (!_persistedStateEnabled) { 
    return undefined; 
  }
  
  // special case.  if app has not finished initializing yet, then we do not want to create new persisted state
  if (!state?.task?.init) { 
    return undefined; 
  }
  
  // if the user has not passed the verification step, then there is no need to store the current route
  // let them start over in this case
  if (!state?.preferences?.protectedMode) {
    return undefined;
  }

  // if the user is in public device mode (such as kiosk mode on a provider tablet), 
  // then there is no need to store persisted state
  if (state?.preferences?.isPublicDevice) { 
    return undefined;
  }
  
  let patient: any = state.patient || {};
  let appt: any = state.appt || {};
  let delta: any = state.delta || {};
  let task: any = state.task || {};
  let preferences: any = state.preferences || {};

  let patientId: string = patient.patientId || "";
  let apptIds: string[] = (appt.allAppointmentInfos || []).map(v => v.apptId);
  let languageIsoCode: string = preferences.languageIsoCode || "";
  let route: string = task.route || "";
  let valid: boolean = !!(patientId && apptIds.length > 0 && route);
  let advDirSelection: string = patient.advancedDirective?.selection || "";
  
  // grab keys for true delta values
  let deltaKeys: string[] = Object.entries(delta).map(([k, v]) => (v? k: "")).filter(v => !!v);

  return {
    patientId,
    apptIds,
    languageIsoCode,
    route,
    valid,
    advDirSelection,
    deltaKeys,
  };
};

export const invalidatePersistedState: any = createAsyncThunk("persisted/invalidatePersistedState", (_ignored: any, thunkAPI) => {
  let newState = _collectPersistedAppState(thunkAPI.getState());
  if (newState !== undefined) {
    thunkAPI.dispatch(_setPersistedState(newState));
  }
});

export const clearAndDisablePersistedState: any = createAsyncThunk("persisted/clearPersistedState", (_ignored: any, thunkAPI) => {
  _persistedStateEnabled = false;
  let emptyState = { ...initialState };
  thunkAPI.dispatch(_setPersistedState(emptyState));
});

const persistedSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    _setPersistedState(state, action: PayloadAction<PersistedState>) {
      _mergePersistedState(state, action.payload);
    },
  },
});


// NOTE: do not export internal reducer actions
const { _setPersistedState } = persistedSlice.actions;

export default persistedSlice.reducer;

