import moment from "moment";
import { GetCdoShortName } from "../../data/CDO";
import { PatientState } from "../../features/patient/patientSlice";
import { parseBool } from "../../utility/utilityFunctions";
import { 
  CompletionAppointmentDataSchema, 
  CompletionCheckinDataSchema, 
  CompletionPatientDataSchema, 
  TrackedCompletionDataSchema,
  TrackedInsuranceDataSchema,
  TrackedPatientDataSchema,
} from "./CompletionDataSchema";


/**
 * Collect and transform appointment and paitent data from redux.
 * This will be used by the completion API to send to the backend.
 * The data should match the expected interface schema
 */
export const collectCompletionCheckinData = (patientState: PatientState, appointmentIds: string[], providerName: string): CompletionCheckinDataSchema => {
  let patient = collectCompletionPatientData(patientState);
  let appointments = collectCompletionAppointmentData(appointmentIds, providerName);

  return {
    appointments,
    patient,
  };
};

export const collectTrackedCompletionDataFromState = (state: any): TrackedCompletionDataSchema => {
  let patient: TrackedPatientDataSchema | undefined = undefined;
  let insurance: TrackedInsuranceDataSchema | undefined = undefined;

  if (state?.patient) {
    patient = collectCompletionPatientData(state.patient) as TrackedPatientDataSchema;
  }
  if (state?.insurance) { 
    insurance = { ...state.insurance } as TrackedInsuranceDataSchema;
  }
  
  return {
    patient,
    insurance,
  };
};

const collectCompletionAppointmentData = (appointmentIds: string[], providerName: string): CompletionAppointmentDataSchema[] => {

  const now = moment();
  const checkinCompleteDate = now.format("YYYY-MM-DD")
  const checkinCompleteStatus = "Y";
  const cdoShortName = GetCdoShortName();

  return (appointmentIds || []).map((appointmentId: string) => ({
    cdoName: cdoShortName,
    appointmentId,
    providerName,
    checkinCompleteStatus,
    checkinCompleteDate,
  }));
};

const collectCompletionPatientData = (patientState: PatientState): CompletionPatientDataSchema => {

  const patient: PatientState = patientState || {};
  const addr = patient.address || {};
  const emergencyContact = patient.emergencyContact || {};

  return {
    patientId: patient.patientId,
    email: patient.email,
    homePhone: patient.homePhone,
    mobilePhone: patient.cellPhone,
    workPhone: patient.workPhone,
    workPhoneExtn: patient.workPhoneExtn,
    prefPhone: patient.preferredPhoneTypeCode,
    streetAddrL1: addr.address1 || "",
    streetAddrL2: addr.address2 || "",
    city: addr.city || "",
    state: addr.state || "",
    zip: addr.zipcode || "",
    emailConsent: patient.consentToEmail,
    textConsent: _toConsent(true),                    // NOTE: we don't collect this, but just assume consent since they clicked on the initial link
    genderIdentity: patient.demographics?.genderIdentity || "",
    prefPronoun: patient.demographics?.preferredPronoun || "",
    race: patient.demographics?.race || "",
    ethnicity: patient.demographics?.ethnicity || "",
    maritalStatus: patient.demographics?.maritalStatus || "",
    religion: patient.demographics?.religiousAffiliation || "",    
    primaryLanguageCode: patient.cdoLanguageKey,      // NOTE: this is not the ISO language code, but rather the CDO specific language key
    primaryLanguage: patient.cdoLanguageName,         // NOTE: intentionally using existing patient.cdoLanguageName
    emergContFirstName: emergencyContact.first_name || "",
    emergContLastName:emergencyContact.last_name || "",
    emergContPhone: _findBestPhone(emergencyContact.cellPhone, emergencyContact.homePhone, emergencyContact.workPhone),
    emergContRelation: emergencyContact.relationship || "",
    sexAssignedAtBirth: patient.demographics?.sexAtBirth || "",
  };
};

const _toConsent = (str: any): string => {
  return parseBool(str, false)? "Y": "N";
};

const _findBestPhone = (...vals: string[]): string => {
  let retval: string = "";
  vals = (vals || []).filter(v => !!v);
  if (vals && vals.length > 0) { 
    retval = vals[0];
  }
  return retval;
};
