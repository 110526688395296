import isEmpty from "lodash/isEmpty";


export interface AddressObj {
  address1: string;
  address2: string;
  city: string;
  state: string; 
  zipcode: string;
  zipcode4?: string;
}

export const CreateEmptyAddressObj = () => {
  return {
    address1: "",
    address2: "",
    city: "",
    state: "", 
    zipcode: "",
    zipcode4: "",
  } as AddressObj;
};

export const CreateNewAddressObj = (
  address1: string = "",
  address2: string = "",
  city: string = "",
  state: string = "",
  zipcode: string = "",
  zipcode4: string = "",
) => {
  return {
    address1,
    address2,
    city,
    state, 
    zipcode,
    zipcode4,
  } as AddressObj;
};

/**
 * Returns true if addrObj is falsey or all fields are empty
 * @param addrObj 
 * @returns boolean
 */
export const isAddressEmpty = (addrObj: AddressObj): boolean => {
  return !addrObj || Object.values(addrObj).every(x => (x === null || x === undefined || x === ""));
};

/**
 * Returnes an array of 0, 1, 2 or 3 address line strings
 * expects address to have this schema
 * { address1, address2, city, state, zipcode }
 * @param address  addressObj.  assumes input is an address object already (not a string)
 * @returns string[]  [addressLine1, addressLine2]
 */
export const compareAddressObjects = (a: AddressObj, b: AddressObj, ignoreCase: boolean = true) => {
  let cmp: number = 0;
  if (a && b) { 
    let strA: string = formatAddressLines(a).join("|");
    let strB: string = formatAddressLines(b).join("|");
    if (ignoreCase) { 
      strA = strA.toLowerCase();
      strB = strB.toLowerCase();
    }
    cmp = strA.localeCompare(strB);
  } else if (a) { 
    cmp = +1;
  } else if (b) { 
    cmp = -1;
  }
  return cmp;
};

/**
 * Returnes an array of 0, 1, 2 or 3 address line strings
 * expects address to have this schema
 * { address1, address2, city, state, zipcode }
 * @param address  addressObj.  assumes input is an address object already (not a string)
 * @returns string[]  [addressLine1, addressLine2]
 */
export const formatAddressLines = (addrObj: AddressObj|string|undefined|null): string[] => {
  if (typeof addrObj === "string") { return [ addrObj ]; }
  
  let lines: string[] = [];
  if (addrObj) { 
    let addrStreetLines = formatAddressStreetLines(addrObj);
    if (!isEmpty(addrStreetLines)) {
      lines = [ ...lines, ...addrStreetLines ];
    }
    
    // NOTE: this may be line 3, if address2 exists
    let addrCityStateZip: string = formatAddressCityStateZip(addrObj);
    if (addrCityStateZip) { 
      lines.push(addrCityStateZip);
    }
  }
  return lines;
};

/**
 * Only includes the address street.  not the city, state or zip.
 * Returnes an array of 0, 1 or 2 address line strings.
 * expects address to have this schema
 * { address1, address2, city, state, zipcode }
 * @param address  addressObj.  assumes input is an address object already (not a string)
 * @returns string[]  [addressLine1, addressLine2]
 */
export const formatAddressStreetLines = (addrObj: AddressObj|string|undefined|null): string[] => {
  if (typeof addrObj === "string") { return [ addrObj ]; }
  
  let lines: string[] = [];
  if (addrObj) { 
    let line1: string = "";
    let line2: string = "";
    if (addrObj.address1 && addrObj.address2) {
      line1 = addrObj.address1;
      line2 = addrObj.address2;
    } else {
      line1 = addrObj.address1 || addrObj.address2 || "";
    }

    if (line1) { lines.push(line1); }
    if (line2) { lines.push(line2); }
  }
  return lines;
};

/**
 * Only returns the city, state, zip as a single string.
 * Returnes a a string
 * expects address to have this schema
 * { address1, address2, city, state, zipcode }
 * @param address  addressObj.  assumes input is an address object already (not a string)
 * @returns string[]  [addressLine1, addressLine2]
 */
export const formatAddressCityStateZip = (addrObj: AddressObj|string|undefined|null): string => {
  if (typeof addrObj === "string") { return addrObj; }
  
  let cityStateZip: string = "";
  if (addrObj) { 
    if (addrObj.city || addrObj.state || addrObj.zipcode) {
      if (addrObj.city && addrObj.state) { 
        cityStateZip = `${addrObj.city}, ${addrObj.state}`;
      } else if (addrObj.city || addrObj.state) {
        cityStateZip = addrObj.city || addrObj.state || "";
      }
      if (addrObj.zipcode) { 
        if (cityStateZip) { cityStateZip += " "; }
        cityStateZip += addrObj.zipcode;
        if (addrObj.zipcode4) { 
          cityStateZip += "-" + addrObj.zipcode4;
        }
      }
    }
  }
  return cityStateZip;
}
