// converted from spreadsheet using
// https://csvjson.com/csv2json
// options; transpose, array

export const CaliVisitTypeNames = {
  // "code": "name",
  "FUV": "Follow-Up Video Visit",
  "TEV": "Video Team Routine Appointment",
  "VNE": "New Video Visit",
  "NEV": "New Video Visit",
  "VPD": "Video Post-Discharge Visit",
  "VVR": "Video Visit Routine",
  "FPN": "Follow-Up Phone",
  "PHN": "Telephone Visit",
  "PSE": "Phone Session",
  "SPH": "Senior Phone Visit",
  "TEL": "Telephone Time",
  "TEP": "Phone Team Appointment",
  "*R*": "*R*",
  "ABD": "ABDOMINAL ULTRA SOUND",
  "ABS": "ABI WITH SPIROMETRY PT",
  "ANT": "ANTIBIOTIC INJECTION",
  "AQT": "AQUATIC THERAPY",
  "AR1": "Application And Removal 1",
  "AR2": "Application And Removal 2",
  "ARS": "Hearing Aid Check Short",
  "AWV": "AWV",
  "AXR": "ABDOMEN X-RAY",
  "AZO": "Basic Hearing Test",
  "B15": "BEHAVIORAL MEDICINE 15",
  "B2H": "BEHAVIORAL MEDICINE TWO HOURS",
  "B30": "BEHAVIORAL MEDICINE 30",
  "B45": "BEHAVIORAL MEDICINE 45",
  "B60": "BEHAVIORAL MEDICINE 60",
  "BAA": "BONE ANCHORED HEARING AID CONSULT",
  "BAL": "Balance Class",
  "BCK": "Back Therapy",
  "BDS": "BONE DENSITY SCAN",
  "BEC": "Balance Education Class",
  "BIO": "BIOPSIES",
  "BLD": "BLOOD WORK",
  "BOT": "BOTOX",
  "BPC": "BLOOD PRESSURE CHECK",
  "BTC": "Botox Consultation",
  "BTX": "Botox Injection",
  "BUS": "BREAST ULTRASOUND",
  "C30": "C30 CONSULTATION",
  "C40": "CONSULTATION 40",
  "C45": "Consultation 45",
  "CAS": "CASTING",
  "CCP": "Comprehensive Care Program",
  "CEE": "COMPLETE EYE EXAM",
  "CHE": "COMPREHENSIVE HEALTH EXAM",
  "CHU": "CHEST ULTRA SOUND",
  "CHX": "CHEST XRAY",
  "CKD": "CHRONIC KIDNEY DISEASE",
  "CLD": "Contact Lens Dispense",
  "CLE": "Contact Lens Fitting",
  "CLO": "CONTACT LENS ORDER",
  "CLR": "CONTACT REORDER",
  "CO1": "COS1 15MIN.",
  "CO2": "COS2 30MIN.",
  "COL": "COLPOSCOPY",
  "COM": "COMMENT",
  "CON": "Consultations",
  "COS": "COSMETIC VISIT",
  "CPA": "Complete Physical Adult",
  "CPE": "COMPLETE PHYSICAL EXAM",
  "CRC": "CONTACT LENS RECHECK",
  "CRD": "CAROTID ULTRA SOUND",
  "CRY": "CRYO",
  "CSP": "COOLSCULPTING",
  "CT1": "CT STUDY 1",
  "CT2": "CT STUDY",
  "CUS": "CUS",
  "CXR": "CHEST X-RAY",
  "CYC": "Cycloplegic Refraction",
  "CYS": "CYSTOSCOPY",
  "DBF": "DIABETIC F/U",
  "DEX": "DEXA SCAN",
  "DFE": "Dilated Fundus Exam",
  "DFU": "DIABETIC FOLLOW UP",
  "DIA": "DIAGNOSTIC",
  "DIB": "DIABETIC CONSULTATION",
  "DIV": "DIABETES IVR EDUCATION",
  "DSE": "DOBUTAMINE STRESS ECHO",
  "E40": "ELECTROMYOGRAP",
  "E60": "ELECTROMYOGRAPH",
  "ECH": "Echo Ultra Sound",
  "EEG": "Electroencephalogram",
  "EGC": "ENDOSCOPY-COLONOSCOPY",
  "EMB": "EMB",
  "EMG": "ELECTROMYOGRAM",
  "EMI": "EAR MOLD IMPRESSION",
  "EN2": "EXTENDED NURSING 60MIN",
  "END": "ENDOCRINOLOGY VISIT",
  "EST": "STRESS TEST",
  "EXE": "EXERCISE EVALUATION",
  "EXT": "EXTREMITY CT",
  "EYE": "DIABETIC EYE SCREENING",
  "FBC": "FULL BODY SKIN CHECK",
  "FCC": "FOLEY CATHETER CHANGE",
  "FLU": "Influenza Vaccine",
  "FRM": "Form Fee",
  "FUN": "FUNDUS PHOTO",
  "FUO": "Follow-up Office Visit",
  "FXR": "FACIAL X-RAY",
  "GDM": "GDM CLASS",
  "GLD": "Glass Dispense",
  "GLO": "Glass Order",
  "GLR": "Glass Reorder",
  "GM2": "GYM TREATMENT F/U",
  "GRC": "Glasses Recheck",
  "GYN": "GYN OFFICE VISIT",
  "GRP": "GRP",
  "HA3": "Third Party Physical",
  "HAA": "Routine Physical",
  "HDI": "HOSPITAL DISCHARGE",
  "HED": "HEAD ULTRA SOUND",
  "HEE": "Healthy Eye Exam",
  "HLR": "HOLTOR MONITOR REMOVAL",
  "HM1": "HEALTHY ME-PART 1 IN PERSON WITH MA",
  "HM2": "HEALTHY ME -PART 2 IN PERSON WITH PROVIDER",
  "HMV": "HEALTHY ME VISIT",
  "HOL": "HOLTER",
  "HVS": "HVS",
  "ICV": "INITIAL COMPREHENSIVE VISIT",
  "IGT": "INGROWN TOENAIL REMOVAL",
  "IJT": "INJECTION",
  "IN1": "INF 15MIN",
  "IN2": "INF 30MIN",
  "IN3": "INF 90MIN",
  "IN4": "INF 2 HOURS",
  "IN5": "INF 4 HOURS",
  "IN6": "INF 5 HOURS",
  "IN7": "INF 6 HOURS",
  "IN8": "INF 8 HOURS",
  "INF": "INFUSION",
  "INJ": "Injury",
  "INS": "INSULIN PUMP",
  "IOB": "INITIAL OB",
  "IOV": "INJECTION OFFICE VISIT",
  "IPL": "IPL PHOTOFACIAL",
  "ITT": "INJECTION TREATMENT",
  "IUD": "INTRA UTERINE DEVICE",
  "IVH": "INITIAL COMPREHENSIVE VILLAGE HEALTH",
  "JIC": "JOINT INJ CLINIC",
  "LAB": "LAB VISIT",
  "LBR": "Lower Body Rehab",
  "LBX": "Light Box",
  "LCN": "Long Consultation",
  "LEP": "Leep",
  "LEX": "LOWER EXTREMITY X-RAY",
  "LHR": "LASER HAIR REMOVAL",
  "LNV": "Long Nursing Visit",
  "LOV": "Long Office Visit",
  "LPR": "Long Procedure",
  "LRE": "Long Reserve Time",
  "LSR": "LASER VISIT",
  "LYC": "LYMPEDEMA COMPLEX",
  "LYE": "LYMPHEDEMA EVALUATION",
  "LYS": "LYMPEDEMA SIMPLE",
  "MAI": "MAMMO,SCREEN W/IMPLANTS",
  "MAS": "MAMMOGRAM SCREENING",
  "MCN": "MICRO NEEDLE",
  "MFC": "MEDICAL FOOT CARE",
  "MIO": "METER TEACH",
  "MOH": "Moh's Surgery",
  "MRI": "MAGNETIC RESONANCE IMAGING",
  "MTH": "Methaltholine",
  "MTR": "METERS INSTRUCTION",
  "NBR": "NEW BREASTFEEDING CONSULT",
  "NDC": "NEW DIABETES CONSULT",
  "NEC": "NEW CONSULTS",
  "NEW": "New Visit",
  "NEX": "NEW EXERCISE PHONE",
  "NGC": "NEW GESTATIONAL CONSULT",
  "NGL": "NEW PATIENT - GLENDALE",
  "NH3": "NH3",
  "NHA": "NEW HEALTH ASSESSMENT",
  "NNC": "NEW NUTRITION CONSULT",
  "NOB": "NEW OB",
  "NPE": "New Patient Complete Physical Exam",
  "NPT": "NEW PUMP TRAIN",
  "NPV": "NEW PATIENT",
  "NRE": "NORPLANT REMOVAL",
  "NSM": "NEW SMOKING CESSATION CONSULT",
  "NST": "NON STRESS TEST",
  "NSV": "Nursing Visit",
  "NT2": "Nuchal Translucency 2",
  "NTC": "Nuchal Translucency",
  "NTL": "NEUROLOGY TEST LONG",
  "NTS": "NEUROLOGY TEST SHORT",
  "NUR": "NURSE VISIT",
  "NVE": "NEW VISIT ENDO",
  "NVL": "NURSE VISIT LONG",
  "NVM": "NURSE VISIT MODERATE",
  "NVO": "NURSE VISIT ONLY",
  "NVS": "NURSE VISIT SHORT",
  "NWB": "NEW WELL BABY CHECK",
  "NWW": "NWW",
  "O05": "OFFICE VISIT 5",
  "O10": "OFFICE VISIT O10",
  "O15": "Post Op 15 Min",
  "O20": "OFFICE VISIT 20",
  "O30": "Office Visit 30",
  "O45": "OFFICE VISIT 45",
  "OBC": "OB COMPLETE ULTRASOUND",
  "OBE": "OBG-MH ENDOMETRIAL",
  "OBH": "OB HISTORY",
  "OBP": "OB PHYSICAL",
  "OBR": "OB RECHECK",
  "OBU": "OB U/S",
  "OBV": "OB PRENATAL",
  "OCI": "OBG-MH CIRCUMCISON",
  "OCN": "Orthoconsult",
  "OCO": "OBG-MH COLPOSCOPY",
  "OFV": "Office Visit",
  "ONS": "OBG-MH NONSTRESS TEST",
  "OTC": "Over The Counter",
  "OV3": "OFV 30 MINUTES",
  "P20": "HRC PAC 20 min",
  "P30": "PROCEDURE 30 MINS",
  "P40": "HRC PAC 40 min",
  "P60": "HRC Post-Acute 60 min",
  "PAC": "PACEMAKER VISIT",
  "PAD": "PERIPHERAL ARTERIAL DISEASE",
  "PAN": "PERPHERAL ARTERIAL-NEUROPATHY",
  "PAP": "Pap Smear",
  "PC3": "PROCEDURE30",
  "PDV": "Post Discharge Visit",
  "PEC": "PAIN EXPERIENCE CLASS",
  "PEF": "PHYSICAL EXAM WITH FORMS",
  "PEL": "PELVIC ULTRASOUND",
  "PER": "New Problem/Follow-up",
  "PES": "PESSARY CHECK",
  "PEV": "Pediatric Evaluation",
  "PFT": "Pulmonary Function Test",
  "PGL": "PLAGIOCEPHALY LONG",
  "PGS": "PLAGIOCEPHALY SHORT",
  "PNC": "PERIPHERAL NEUROPATHY CHECK",
  "POP": "POST OP VISIT",
  "POV": "Post-Op Visit",
  "PPR": "POST PARTUM",
  "PRE": "Preop Evaluation",
  "PRO": "Provider Training",
  "PST": "Pulmonary Stress Test",
  "PTL": "PHYSICAL THERAPY LONG",
  "PTR": "Patient Registraion",
  "PTS": "Paratyroid Scan",
  "PWP": "CPE WITH PAP",
  "PCO": "Phone Consultation",
  "PSC": "Phone Short Consultation",
  "PRC": "Phone Recheck",
  "PRS": "Phone Recheck short",
  "PHL": "Phone Visit Long",
  "PHS": "Phone Visit Short",
  "QME": "WORKERS COMP EVALUATION",
  "R15": "RADIOLOGY 15 MIN",
  "R20": "RADIOLOGY 20 MIN",
  "R30": "RADIOLOGY 30 MIN",
  "R45": "RADIOLOGY 45 MIN",
  "RDC": "RETURN DIABETES CONSULT",
  "REC": "Recheck",
  "REG": "Regular Visit Type",
  "REL": "RECHECK LONG",
  "REM": "REMICADE",
  "REP": "Repair (glasses)",
  "REV": "RE-EVAL",
  "RFC": "ROUTINE FOOT CARE",
  "RGC": "RETURN GESTATIONAL CONSULT",
  "RHC": "Rehabclinic",
  "RNC": "RETURN NUTRITION CONSULT",
  "ROU": "ROUTINE OFFICE VISIT",
  "ROV": "RETURN OFFICE VISIT",
  "RPT": "RETURN PUMP TRAINING",
  "RSM": "RETURN SMOKING CESSATION CONSULT",
  "RSP": "RECHECK WITH SPECIALIST",
  "RTC": "ROSCOE TOWER COLPOSCOPY",
  "RTE": "ROSCOE TOWER ENDOM BX",
  "RTU": "ROSCOE TOWER ULTRASOUND",
  "RTY": "RESTALYNE",
  "RXR": "RIB X-RAY",
  "S2H": "SURGERY 2 HRS",
  "S30": "SURGERY 30 MINUTES",
  "S45": "MINOR SURGERY",
  "S60": "Surgery 60",
  "S90": "SURGERY 90 MINUTES",
  "SAC": "SHORT HEARING AID CONSULT",
  "SAF": "Short Hearing Aid Fit",
  "SAV": "Saved Consult",
  "SCL": "SCLEROTHERAPY",
  "SCN": "Short Consults",
  "SDV": "SHARED DECISION VISIT",
  "SET": "STRESS ECHO",
  "SHA": "SHORT HEALTH ASSESMENT",
  "SIC": "SAME DAY / NEXT DAY",
  "SIG": "SIGMOIDOSCOPY",
  "SLV": "SHORT LAB VISITS",
  "SNV": "Short Nursing Visit",
  "SNX": "SINUS X-RAY",
  "SOP": "SECOND OPINION",
  "SOV": "SHORT OFFICE VISIT",
  "SPI": "Spirometry Screenting",
  "SPN": "SPINE X-RAY",
  "SPR": "Special Recheck",
  "SPV": "SPINE AND PELVIS VISIT",
  "SRE": "Short Reserve Time",
  "SRM": "SUTURE REMOVAL",
  "SSG": "ENT MINOR SURGERY",
  "STH": "SHORT TEAM HEALTH ASSESSMENT",
  "SUR": "Minor Surgery",
  "SV1": "SNV1 15MIN",
  "SV2": "SNV2 30MIN",
  "SV3": "SNV3 45MIN",
  "SV4": "SNV4 60MIN",
  "SV5": "SNV5 90MIN",
  "SXC": "SXC",
  "SXR": "SKULL X-RAY",
  "TEA": "Team Visit",
  "TER": "TEAM APPOINTMENT ROUTINE",
  "TES": "TEAM SICK ACUTE VISIT",
  "TH3": "TH3",
  "THA": "Thallium Treadmill",
  "THY": "THYROID ULTRA SOUND",
  "TPD": "TEAM POST DISCHARGE VISIT",
  "TPR": "TPR",
  "TRE": "TREADMILL",
  "TWB": "TEAM WELL BABY CHECK",
  "TWW": "TWW",
  "UEX": "UPPER EXTREMITY X-RAY",
  "UGC": "UGC",
  "ULS": "ULTRASOUND",
  "URG": "URGENT VISIT",
  "URO": "Bladder Testing",
  "US1": "U/S 1",
  "US2": "U/S STUDY",
  "VAS": "VASECTOMY",
  "VBM": "V BEAM",
  "VEN": "VENOUS DOPLER ULTRA SOUND",
  "VFE": "VISUAL FIELD EXAM",
  "VHV": "VILLAGE HEALTH COMPREHENSIVE VISIT",
  "VIS": "VIS",
  "VM1": "Vaccine Moderna 1st dose",
  "VM2": "Vaccine Moderna 2nd dose",
  "VNG": "VNG",
  "VP1": "Vaccine Pfizer dose 1",
  "VP2": "Vaccine Pfizer dose 2",
  "VRE": "VILLAGE HEALTH ROUTINE FOLLOW UP / RECHECK",
  "VZO": "VESTIBULAR WORK UP",
  "WBC": "WELL BABY CHECK",
  "WCK": "WOUND CHECK",
  "WCN": "WOUND CONSULTATION",
  "WCR": "WORKERS COMP RECHECK",
  "WNV": "WOUND NURSING VISIT",
  "WOU": "WOUND CARE",
  "WRV": "WOUND RETURN OFFICE VISIT",
  "WWE": "WELL WOMAN EXAM",
  "XE1": "XE1",
  "XEC": "EXERCISE ECHO",
  "XR1": "X-RAY STUDY",
  "XTR": "XTRAC LASER",
  "XXX": "METER TEACH",
  "YAG": "YAG LASER PROCEDURE",
  "ZIO": "ZIOPATCH",

  // NOTE: not in spreadsheet
  "TCP": "TCP",       
};

