import { createGenerateClassName, createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

// NOTE: Mui4v5 this is used to prevent the makeStyles from mui v4 and v5
// from using the same auto generated class namespace

// For reference, here is a PCI style guide
// https://github.optum.com/pages/catalyst-ux/pci/style/index.html

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

export const generateAppClassName = createGenerateClassName({
  productionPrefix: "app-",
});

// NOTE: these values needs to match value in App.css
export const appMaxWidth: number = 900;
export const appMinHeight: number = 300;

export const AppThemeColorKeys: any = {
  lightGray: "lightGray",
  mediumGray: "mediumGray",
  darkGray: "darkGray",
  orange: "orange",
  green: "green",
  purple: "purple",
  blue: "blue",
  maroon: "maroon",
  white: "white",
  black: "black",
  scrimBlack: "scrimBlack",
  transparent: "transparent",
};

export const appFonts: any = {
  light: "UHCSansLight",
  regular: "UHCSansRegular",
  medium: "UHCSansMedium",
  semibold: "UHCSansSemiBold",
  bold: "UHCSansBold",
  altSemibold: "SF Pro Text Semibold",
  semibolditalic: "UHCSansSemiBoldItalic", // TODO: not present in fonts need to add
};

// font weight mappings
export const appFontWeightMap: any = {
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  altSemibold: 600,
  semibolditalic: 700,
};

export const appAlphas: any = {
  a03: 0.3,
  a04: 0.4,
  a05: 0.5,
  a06: 0.6,
  a07: 0.7,
  scrim: 0.6,
};

export const appDialogTransparentPaperProps: any = {
  style: {
    backgroundColor: "transparent",
    boxShadow: "none",
    backdropFilter: "blur(10px)",
  },
};

export const appDialogTransparentPaperPropsCenter: any = {
  style: {
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    boxShadow: "none",
    backdropFilter: "blur(10px)",
  },
};

export const appColors: any = {
  white: "#FFFFFF",
  black: "#000000",
  offwhite: "#F5F5F5",
  transparent: "transparent",

  whiteA07: "rgba(255,255,255,0.7)",
  whiteA05: "rgba(255,255,255,0.5)",
  whiteA03: "rgba(255,255,255,0.3)",
  whiteA01: "rgba(255,255,255,0.1)",
  blackA07: "rgba(0,0,0,0.7)",
  blackA05: "rgba(0,0,0,0.5)",
  blackA03: "rgba(0,0,0,0.3)",
  blackA01: "rgba(0,0,0,0.1)",
  blackScrim: "rgba(0,0,0,0.6)",

  success: "#0B8D0F",
  success1: "#18A468",
  success2: "#0B8D0F",
  warn: "#F5B700",
  warn1: "#F5B700",
  warn2: "#F5B700",
  danger: "#EE0010",
  danger1: "#EE0010",
  danger2: "#EE0010",
  selection: "#0090F3",
  focus: "#FFFFFF",
  focusA07: "rgba(255, 255, 255, 0.7)",
  focusA05: "rgba(255, 255, 255, 0.5)",
  focusA03: "rgba(255, 255, 255, 0.3)",
  focusA01: "rgba(255, 255, 255, 0.1)",

  lightGray1: "#9E9E9E",
  lightGray2: "#939393",
  mediumGray1: "#6E6E6E",
  mediumGray2: "#636363",
  darkGray1: "#222222",
  darkGray2: "#444444",

  orange1: "#C80000",
  orange2: "#FFA200",
  orangeMid: "#E85800",

  green1: "#00B97A",
  green2: "#00A5C9",
  greenMid: "#00AFA4",

  purple1: "#BA5ABF",
  purple2: "#5A3395",
  purpleMid: "#8945AA",

  blue1: "#008191",
  blue2: "#0090F3",
  blueMid: "#0089C3",

  // maroon is used on error pages
  maroon1: "#680000",
  maroon2: "#9C005C",
  maroonMid: "#82002E",

  darkError: "#F05973",
};

export const makeGradient = (
  color1,
  color2,
  deg = "0deg",
  start = "0%",
  stop = "100%"
) => {
  return `linear-gradient(${deg}, ${color1} ${start}, ${color2} ${stop})`;
};

export const appStyles: any = {
  // layout and utility styles
  fillxy: {
    width: "100%",
    height: "100%",
  },
  fillx: {
    width: "100%",
  },
  filly: {
    height: "100%",
  },
  flexfill: {
    flex: "1 0 auto",
  },

  absxy: {
    position: "absolute",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
  },
  absx: {
    position: "absolute",
    left: "0",
    right: "0",
  },
  absy: {
    position: "absolute",
    top: "0",
    bottom: "0",
  },

  scrollx: {
    overflowX: "auto",
    overflowY: "hidden",
  },
  scrolly: {
    overflowX: "hidden",
    overflowY: "auto",
  },

  clickable: {
    cursor: "pointer",
  },
  nopointer: {
    pointerEvents: "none",
  },

  nowrap: {
    flexWrap: "nowrap",
  },

  // for accessibility
  focusVisible: {
    backgroundColor: appColors.focusA01,
    boxShadow: `0 0 1px 1px ${appColors.focusA03}`,
  },

  // text font styles
  fontRegular: {
    fontFamily: appFonts.regular,
  },
  fontMedium: {
    fontFamily: appFonts.medium,
  },
  fontSemibold: {
    fontFamily: appFonts.semibold,
  },
  fontBold: {
    fontFamily: appFonts.bold,
  },
  fontAltSemibold: {
    fontFamily: appFonts.altSemibold,
  },

  // common text styles
  textLeft: { textAlign: "left" },
  textCenter: { textAlign: "center" },
  textRight: { textAlign: "right" },

  // elements
  hdivider: {
    marginTop: "20px",
    marginBottom: "20px",
    width: "100%",
    maxWidth: "100%",
    height: "3px",
    borderRadius: "5px",
    background: "rgba(255, 255, 255, 0.5)",
  },

  inputUnderlineBorder: {
    "&:before": {
      borderBottom: "1px solid rgba(255,255,255,0.4)",
    },
    "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottom: "1px solid rgba(255,255,255,0.6)",
    },
    "&:after": {
      borderBottom: "1px solid rgba(255,255,255,0.7)",
    },
  },

  inputUnderlineColors: {
    "&:before": {
      borderBottomColor: "rgba(255,255,255,0.4)",
    },
    "&:after": {
      borderBottomColor: "rgba(255,255,255,0.7)",
    },
  },

  arInsuranceCard: {
    // aspect ratio 1.555
    // 100% * 1.555
    paddingTop: "155.55%",
    height: "auto",
  },

  // solid babkground color fills
  bgLightGray: {
    backgroundColor: appColors.lightGray1,
    color: appColors.white,
  },
  bgMediumGray: {
    backgroundColor: appColors.mediumGray1,
    color: appColors.white,
  },
  bgDarkGray: {
    backgroundColor: appColors.darkGray1,
    color: appColors.white,
  },

  // gradient background color fills
  bgDarkGrayGrad: {
    backgroundColor: "initial",
    background: makeGradient(appColors.darkGray1, appColors.darkGray2, "78deg"),
    color: appColors.white,
  },
  bgOrangeGrad: {
    backgroundColor: "initial",
    background: makeGradient(appColors.orange1, appColors.orange2, "20deg"),
    color: appColors.white,
  },
  bgGreenGrad: {
    backgroundColor: "initial",
    background: makeGradient(appColors.green1, appColors.green2, "75deg"),
    color: appColors.white,
  },
  bgPurpleGrad: {
    backgroundColor: "initial",
    background: makeGradient(appColors.purple1, appColors.purple2, "48deg"),
    color: appColors.white,
  },
  bgBlueGrad: {
    backgroundColor: "initial",
    background: makeGradient(appColors.blue1, appColors.blue2, "60deg"),
    color: appColors.white,
  },
  bgMaroonGrad: {
    backgroundColor: "initial",
    background: makeGradient(appColors.maroon1, appColors.maroon2, "60deg"),
    color: appColors.white,
  },
  bgBlack: {
    backgroundColor: appColors.black,
    color: appColors.white,
  },
  bgTransparent: {
    backgroundColor: "transparent",
    color: appColors.white,
  },

  scrimBlack: {
    backgroundColor: `rgba(0, 0, 0, ${appAlphas.scrim})`,
    color: appColors.white,
  },

  stepIconBase: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    boxSizing: "border-box",
    fontSize: "22px",
    fontWeight: 600,
    borderRadius: "30px",
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: appColors.mediumGray2,
    color: appColors.mediumGray2,
    padding: "0",
    background: "transparent",
  },
  stepIconActive: {
    borderWidth: "0",
    padding: "2px",
  },
  stepIconCompleted: {
    background: makeGradient(appColors.success1, appColors.success2, "45deg"),
    color: appColors.white,
    borderWidth: "0",
    padding: "2px",
  },

  stepEmpty: {
    color: appColors.lightGray1,
    fontFamily: appFonts.bold,
  },
  stepSuccess: {
    background: makeGradient(appColors.success1, appColors.success2, "45deg"),
    color: appColors.white,
    fontFamily: appFonts.bold,
  },
  stepOrangeGrad: {
    background: appColors.orangeMid,
    color: appColors.white,
    fontFamily: appFonts.bold,
  },
  stepGreenGrad: {
    background: appColors.greenMid,
    color: appColors.white,
    fontFamily: appFonts.bold,
  },
  stepPurpleGrad: {
    background: appColors.purpleMid,
    color: appColors.white,
    fontFamily: appFonts.bold,
  },
  stepBlueGrad: {
    background: appColors.blueMid,
    color: appColors.white,
    fontFamily: appFonts.bold,
  },
  stepMaroonGrad: {
    background: appColors.maroonMid,
    color: appColors.white,
    fontFamily: appFonts.bold,
  },

  navHeader: {
    position: "relative",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "24px",
    flexWrap: "nowrap",

    fontFamily: appFonts.medium,
    fontSize: "18px",
    lineHeight: "22px",
    color: appColors.white,
  },

  navMain: {
    position: "relative",
    height: "calc(100% - 112px)", // 60 (button) + 2*16 (pad)
    padding: "0 16px",
    flexWrap: "nowrap",
    overflowY: "auto",
  },

  navMainFullH: {
    position: "relative",
    height: "100%",
    padding: "0 16px",
    flexWrap: "nowrap",
    overflowY: "auto",
  },

  navContent: {
    position: "relative",
    height: "100%", // 60 (button) + 2*16 (pad)
    flexWrap: "nowrap",
    overflowY: "auto",
    paddingBottom: "148px",
  },

  navSection: {
    position: "relative",
    paddingLeft: "16px",
    paddingRight: "16px",
    flexWrap: "nowrap",
  },

  /* 
  NOTE: navStickyFooter must be inside a scrollable container and a sibling to navContent.
  navStickyFooter will float on the bottom.  otherwise, navStickyFooter will scroll 
  up with the html or body element which is not desired.  */
  navStickyFooter: {
    position: "absolute",
    flexWrap: "nowrap",
    left: "0",
    right: "0",
    bottom: "0",
    padding: "0 16px 36px",
    background: "linear-gradient(0deg, rgba(40,40,40,1) 5%,rgba(40,40,40,0.7) 50%, rgba(40,40,40,0) 100%)",
    zIndex: "1",
  },

  navScrim: {
    position: "absolute",
    flexWrap: "nowrap",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    backgroundColor: appColors.blackScrim,
    zIndex: 101,
  },

  navScrimContent: {
    postiion: "relative",
    width: "100%",
    height: "100%",
  },

  headerTitle: {
    marginBottom: "0",
    fontFamily: appFonts.bold,
    fontSize: "22px",
    lineHeight: "26px",
    paddingRight: "16px",
    color: appColors.white,
  },

  headerText: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    lineHeight: "22px",
    color: appColors.white,
  },

  headerIcon: {
    fontFamily: appFonts.medium,
    fontSize: "18px",
    lineHeight: "22px",
    color: appColors.white,
    stroke: appColors.white,
    fill: appColors.white,

    "& svg": {
      fontFamily: appFonts.medium,
      fontSize: "18px",
      lineHeight: "22px",
      color: appColors.white,
      stroke: appColors.white,
      fill: appColors.white,
    },
    "&svg": {
      fontFamily: appFonts.medium,
      fontSize: "18px",
      lineHeight: "22px",
      color: appColors.white,
      stroke: appColors.white,
      fill: appColors.white,
    },
  },
};

// NOTE: makeStyles is using an index, which causes the makeStyles
// to get injected at this index in the generated stylesheet.
// we want component specific makeStyles to be able to override these classes
export const useAppTheme: () => any = makeStyles(createStyles(appStyles), {
  name: "appTheme",
  index: 0,
});

export const getThemeBGClass = (theme: any, colorKey?: string) => {
  let cls = null;
  switch (colorKey) {
    case AppThemeColorKeys.lightGray:
      cls = theme.bgLightGray;
      break;
    case AppThemeColorKeys.mediumGray:
      cls = theme.bgMediumGray;
      break;
    case AppThemeColorKeys.darkGray:
      cls = theme.bgDarkGrayGrad;
      break;
    case AppThemeColorKeys.orange:
      cls = theme.bgOrangeGrad;
      break;
    case AppThemeColorKeys.green:
      cls = theme.bgGreenGrad;
      break;
    case AppThemeColorKeys.purple:
      cls = theme.bgPurpleGrad;
      break;
    case AppThemeColorKeys.blue:
      cls = theme.bgBlueGrad;
      break;
    case AppThemeColorKeys.maroon:
      cls = theme.bgMaroonGrad;
      break;
    case AppThemeColorKeys.white:
      cls = theme.bgTransparent;
      break;
    case AppThemeColorKeys.black:
      cls = theme.bgBlack;
      break;
    case AppThemeColorKeys.scrimBlack:
      cls = theme.scrimBlack;
      break;
    case AppThemeColorKeys.transparent:
      cls = theme.bgTransparent;
      break;
    default:
      break;
  }
  return cls;
};

export const getThemeAccentColor = (colorKey?: string): string => {
  let val: string = appColors.orangeMid;
  switch (colorKey) {
    case AppThemeColorKeys.lightGray:
      val = appColors.lightGray1;
      break;
    case AppThemeColorKeys.mediumGray:
      val = appColors.mediumGray1;
      break;
    case AppThemeColorKeys.darkGray:
      val = appColors.darkGray1;
      break;
    case AppThemeColorKeys.orange:
      val = appColors.orangeMid;
      break;
    case AppThemeColorKeys.green:
      val = appColors.greenMid;
      break;
    case AppThemeColorKeys.purple:
      val = appColors.purpleMid;
      break;
    case AppThemeColorKeys.blue:
      val = appColors.blueMid;
      break;
    case AppThemeColorKeys.maroon:
      val = appColors.maroonMid;
      break;
    case AppThemeColorKeys.white:
      val = appColors.white;
      break;
    case AppThemeColorKeys.transparent:
      val = appColors.transparent;
      break;
    default:
      break;
  }
  return val;
};
