import axios from "../interceptors/interceptors";
import { AxiosRequestConfig } from "axios";
import config from "../config"; 

export const SaveSplunkLoggerAPI = async (apiName: string, error: any) => {
  let apiCfg = config.splunkLoggerApi;
  let url: string = apiCfg.url + "SplunkLogger?code=" + apiCfg.code;

  let appName = config.splunkLoggerAppName;
  //Request body
  let erroRequest: string = String(error?.response?.config?.url || "");
  let errorMsg: string = String(error?.response?.data?.message || "");
  let errorStack: string = String(error?.stack || "");

  let splunkErrorMsg = "Request url : " + erroRequest + ", Response Msg : " + errorMsg + ", Stack trace: " + errorStack;
  const body = {
    applicationName: appName + " - " + apiName,
    errorType: "Error",
    errorMessage: splunkErrorMsg
  };

  let data = JSON.stringify(body);
  let req: AxiosRequestConfig = {
    method: "POST",
    url: url,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return axios(req)
    .then((response) => !!response)
    .catch((err) => {
      console.error(
        'Error logging to splunk api => ' + err.message
      );
    });
};
