import isEqual from "lodash/isEqual";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { collectTrackedCompletionDataFromState } from "../../lib/save-state/collectCompletionData";


// We use this redux state to keep track of patient or appointment 
// related data which has changed by the user and which will
// need to be updated by the APIs or CDOs  
// if any of these values are true, that means that category of data
// has changeed warranting an update by the APIs
export interface DeltaState {
  patient: boolean;           // patient data
  insurance: boolean;         // insurance images or data
  signedForms: boolean;       // PDF signed forms
}

const initialState: DeltaState = {
  patient: false,
  insurance: false,
  signedForms: false,
};

// NOTE: there is no signedForms state to compare.  
// the signedForms delta flag must be set manually when forms are saved.
const AllDeltaKeys = Object.keys(initialState);
const TrackedDeltaKeys = [ "patient", "insurance" ];

let _trackedData = {};
let _trackingEnabled = false;

const _mergeDeltaState = (state: DeltaState, newState: DeltaState): void => {
  // merge the state in place (do not create a new state object)
  // only copy values that belong to our expected state object
  // copy values rather than re-assigning a new state object so that 
  // the original state object bindings remain
  if (newState) {
    AllDeltaKeys.forEach(k => {
      state[k] = newState[k];
    });
  }
};

export const invalidateDeltaTrackedState: any = createAsyncThunk("delta/invalidateDeltaTrackedState", (_ignored: any, thunkAPI) => {
  // if tracking is not enabled, then we have not entered any forms that might 
  // have updated completion data yet.
  if (!_trackingEnabled) { 
    return;
  }

  // if a delta flags are for tracked item is already true,
  // then we can skip that comparison
  let oldState: any = thunkAPI.getState();
  let newDeltaState = { ...oldState.delta };
  let deltaStateChanged = false;
  let curCompletionData = collectTrackedCompletionDataFromState(oldState);
  TrackedDeltaKeys.forEach(k => {
    let newTrackedData = curCompletionData[k];
    if (newTrackedData) {
      let oldTrackedData = _trackedData[k];
      if (oldTrackedData === undefined) {
        // if we don't have cached trackedData item yet, init it now.  
        // this will be used as our base for comparison later
        _trackedData[k] = newTrackedData;
      } else if (!newDeltaState[k] && !isEqual(oldTrackedData, newTrackedData)) {
        // if the flag is already set, then we can skip this comparison check
        // if data is changed, set the flag, but do NOT update the old tracked data.  
        // we can keep it the same
        newDeltaState[k] = true;
        deltaStateChanged = true;
      }
    }
  });

  if (deltaStateChanged) { 
    thunkAPI.dispatch(_setDeltaState(newDeltaState));
  }
});

const deltaSlice = createSlice({
  name: "delta",
  initialState,
  reducers: {
    _setDeltaState(state, action: PayloadAction<DeltaState>) {
      _mergeDeltaState(state, action.payload);
    },
    initDeltaKeyValues(state, action: PayloadAction<string[]>) {
      // for any valid delta keys in the list, set the state value to true
      let deltaKeys = action.payload || [];
      deltaKeys.forEach(k => {
        if (k && AllDeltaKeys.includes(k)) { 
          state[k] = true;
        }
      })
    },
    updateDeltaPatientDataChanged(state, action: PayloadAction<boolean>) {
      state.patient = !!action.payload;
    },
    updateDeltaPInsuranceDataChanged(state, action: PayloadAction<boolean>) {
      state.insurance = !!action.payload;
    },
    updateDeltaSignedFormsDataChanged(state, action: PayloadAction<boolean>) {
      state.signedForms = !!action.payload;
    },
    resetDeltaTrackedData(state, action: PayloadAction<boolean>) {
      // NOTE: this action does not set state
      _trackedData = {};
    },
    setDeltaTrackingEnabled(state, action: PayloadAction<boolean>) {
      // NOTE: this action does not set state
      _trackingEnabled = !!action.payload;
    },
  },
});

// NOTE: do not export internal reducer actions
const { _setDeltaState } = deltaSlice.actions;

export const {
  initDeltaKeyValues,
  updateDeltaPatientDataChanged,
  updateDeltaPInsuranceDataChanged,
  updateDeltaSignedFormsDataChanged,
  resetDeltaTrackedData,
  setDeltaTrackingEnabled,
} = deltaSlice.actions;

export default deltaSlice.reducer;

