import { SaveSplunkLoggerAPI } from "./SplunkLoggerAPI";

const shouldBubbleError = (apiName: string): boolean => {
  let retval: boolean = false;
  switch (apiName) { 
    case "UpdateFhirAPI":
    // case "AggreeToTermsOfUseAPI":
      retval = true;
      break;
    default:
      break;
  }
  return retval;
}

const isNetworkError = (err: any): boolean => {
  let retval: boolean = false;
  try {
    if (err) {
      // axios will tell us it's a Network Error if the browser network connection is offline
      let msg: string = String(err?.message || "");
      retval = !!(msg.match(/^Network\s*Error/i));
      if (!retval) { 
        // check for 4XX or 5XX status errors
        let status = String(err?.response?.status || "");
        retval = !!status.match(/^[45]\d+/i)
      }
    }
  } catch (ex) {}
  return retval;
};

let _apiErrorHandler: Function|null = null;

export const SetApiErrorHandler = (handler: Function|null) => {
  _apiErrorHandler = handler || null;
};

export const HandleApiErrorMiddleware = (apiName: string, err: any, suppressError: boolean = false): void => {
  console.warn(err);

  if (_apiErrorHandler && shouldBubbleError(apiName) && isNetworkError(err)) {
    _apiErrorHandler(err);
  }
  
  // splunk logging 
  SaveSplunkLoggerAPI(apiName, err);
  
  if (suppressError) {
    return undefined;
  }
  
  // re-throw the error for any observers
  throw err;
};
