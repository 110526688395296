import _findLast from "lodash/findLast";
import { ChildStep, EmptyTaskStep, ParentStep, TaskStep, TaskStepConfig } from "./taskDefs";

export interface TaskRouteInfo {
  init?: boolean;
  routeMap: any;
  allRoutes: string[];
  visibleRoutes: string[];
  parentSteps: any[];
}

export const empyTaskRouteInfo: TaskRouteInfo = {
  init: false,
  routeMap: {},
  allRoutes: [],
  visibleRoutes: [],
  parentSteps:[],
};

export const findTaskRouteItem = (routeMap: any, route: string): TaskStep|undefined => {
  let step: TaskStep|undefined = undefined;
  if (routeMap && route) { 
    step = routeMap[route];
  }
  return step;
};

export const isTaskRouteIncludedInSteps = (routeMap: any, route: string): boolean => {
  let step: TaskStep|undefined = findTaskRouteItem(routeMap, route);
  return step? isIncludedInSteps(step): false;
};

export const isIncludedInSteps = (step: TaskStep): boolean => {
  let retval = false;
  if (step) {
    if (step.includeInSteps === true || step.includeInSteps === false) { 
      retval = step.includeInSteps;
    } else {
      retval = true;
    }
  }
  return retval;
};

export const countNumSteps = (steps: TaskStep[]): number => {
  let numSteps: number = 0;
  steps.forEach((step: TaskStep) => {
    if (isIncludedInSteps(step)) { 
      numSteps++; 
    }
  });
  return numSteps;
};

export const filterIncludedSteps = (steps: TaskStep[]): TaskStep[] => {
  return (steps || []).filter((step: TaskStep) => {
    return isIncludedInSteps(step);
  })
};

// NOTE: this could return a floating point value
// not found returns -1
// valid index found could return [-0.5, {visibleRoutes.length} + 0.5];
const _getRelativeVisibleRouteIndex = (visibleRoutes: string[], allRoutes: string[], route: string, scanForward: boolean = true): number => {
  let visibleIdx: number = -1;
  if (route && visibleRoutes?.length > 0) { 
    visibleIdx = visibleRoutes.indexOf(route);
    if (visibleIdx < 0 && allRoutes?.length > 0) {
      let tmpIdx1: number = allRoutes.indexOf(route);
      if (tmpIdx1 >= 0) { 
        if (scanForward) { 
          // find the next visible route after target route
          allRoutes.find((curRoute, idx) => {
            if (idx <= tmpIdx1) { return false; }
            let tmpIdx2: number = visibleRoutes.indexOf(curRoute);
            if (tmpIdx2 >= 0) { 
              visibleIdx = tmpIdx2 - 0.5;
              return true;
            }
            return false;
          });
        } else {
          // find the previous visible route before target route
          _findLast(allRoutes, (curRoute, idx) => {
            if (idx >= tmpIdx1) { return false; }
            let tmpIdx2: number = visibleRoutes.indexOf(curRoute);
            if (tmpIdx2 >= 0) { 
              visibleIdx = tmpIdx2 + 0.5;
              return true;
            }
            return false;  
          });
        }
      }
    }
  }
  return visibleIdx;
};

export const getNextTaskRoute = (visibleRoutes: string[], allRoutes: string[], activeRoute: string): any => {
  let newRoute: string = "";
  if (activeRoute) {
    let oldIdx: number = _getRelativeVisibleRouteIndex(visibleRoutes, allRoutes, activeRoute, true);
    if (oldIdx > -1 && oldIdx < visibleRoutes.length - 1) {
      let newIdx: number = Math.floor(oldIdx) + 1;
      newRoute = visibleRoutes[newIdx] || "";
    }
  }
  return newRoute;
};

export const getPrevTaskRoute = (visibleRoutes: string[], allRoutes: string[], activeRoute: string): any => {
  let newRoute: string = "";
  if (activeRoute) {
    let oldIdx: number = _getRelativeVisibleRouteIndex(visibleRoutes, allRoutes, activeRoute, false);
    if (oldIdx > 0) {
      let newIdx: number = Math.ceil(oldIdx) - 1;
      newRoute = visibleRoutes[newIdx] || "";
    }
  }
  return newRoute;
};

export const getStartTaskRoute = (visibleRoutes: string[], initRoute: string = ""): any => {
  let route: string = initRoute;
  if (!route && visibleRoutes && visibleRoutes.length > 0) { 
    route = visibleRoutes[0] || "";
  }
  return route;
};

const _getTaskStepAttributes = (step: any): any => {
  let { isStart, isFinal, isError } = (step || {});
  return { isStart, isFinal, isError };
};

export const buildTaskRouteInfos = (config: TaskStepConfig): TaskRouteInfo => {
  let allRoutes: string[] = [];
  let visibleRoutes: string[] = [];
  let parentSteps: any[] = [];
  let routeMap: any = {};

  let steps: ParentStep[] = config?.steps || [];
  let numParentSteps: number = countNumSteps(steps);

  let routeIdx: number = 0;
  let parentStepIdx: number = 0;
  let parentThemeColor: string = "";
  steps.forEach((parentStep: ParentStep, parentIdx: number) => {
    parentStep = { ...EmptyTaskStep, ...parentStep };       // make sure all steps have all expected empty defaults
    if (parentStep?.route) {
      let parentIncluded: boolean = isIncludedInSteps(parentStep);
      let parentHidden: boolean = !!parentStep.isHidden;
      let childSteps: ChildStep[] = (parentStep.childSteps || []);
      let numChildSteps: number = countNumSteps(childSteps);
      parentThemeColor = parentStep?.themeColor || "";
      let parentInfo = { 
        ...EmptyTaskStep,
        ..._getTaskStepAttributes(parentStep),
        route: parentStep.route,
        parent: parentStep, 
        parentIdx,
        parentStepIdx: parentIncluded? parentStepIdx: -1, 
        numParentSteps, 
        numChildSteps,
        routeIdx,
        included: parentIncluded,
        hidden: parentHidden,
        themeColor: parentThemeColor,
        child: null,
        childIdx: -1,
      };
      if (!parentHidden) {
        visibleRoutes.push(parentStep.route);
      }
      allRoutes.push(parentStep.route);      
      routeMap[parentStep.route] = parentInfo;
      routeIdx++;
      
      if (parentIncluded && !parentHidden) { 
        parentSteps.push({ label: parentStep.label });
      }

      childSteps.forEach((childStep: ChildStep, childIdx: number) => {
        childStep = { ...EmptyTaskStep, ...childStep };   // make sure all steps have all expected empty defaults
        if (childStep?.route) { 
          let fullRoute: string = parentStep.route + "/" + childStep.route;
          let childIncluded: boolean = isIncludedInSteps(childStep);
          let childHidden: boolean = !!childStep.isHidden;
          if (!childHidden) {
            visibleRoutes.push(fullRoute);
          }
          allRoutes.push(fullRoute);
          routeMap[fullRoute] = {
            ...parentInfo,
            routeIdx,
            route: childStep.route,
            included: childIncluded,
            hidden: childHidden,
            themeColor: parentThemeColor,
            child: childStep,
            childIdx,
          };
          routeIdx++;
        } else {
          console.warn("invalid child task step for parent index: " + parentIdx + " and child index: " + childIdx);
        }   
      });

      if (parentIncluded && !parentHidden) { 
        parentStepIdx++; 
      }

    } else { 
      console.warn("invalid parent task step for index: " + parentIdx);
    }
  });
  
  return { init: true, routeMap, allRoutes, visibleRoutes, parentSteps };
};

export const compareTaskRoutes = (allRoutes: string[], routeCmp: string, routeBase: string): number => {
  let retval: number = 0;
  if (allRoutes && allRoutes.length > 0) {
    let cmpIdx: number = allRoutes.indexOf(routeCmp);
    let baseIdx: number = allRoutes.indexOf(routeBase);
    if (cmpIdx >= 0 && baseIdx >= 0) { 
      retval = cmpIdx - baseIdx;
    }
  }
  return retval;
};

export const isTaskRouteBefore = (allRoutes: string[], routeCmp: string, routeBase: string): boolean => {
  return (compareTaskRoutes(allRoutes, routeCmp, routeBase) < 0);
};

export const isTaskRouteAfter = (allRoutes: string[], routeCmp: string, routeBase: string): boolean => {
  return (compareTaskRoutes(allRoutes, routeCmp, routeBase) > 0);
};

export const isTaskRouteSameOrBefore = (allRoutes: string[], routeCmp: string, routeBase: string): boolean => {
  return (compareTaskRoutes(allRoutes, routeCmp, routeBase) <= 0);
};

export const isTaskRouteSameOrAfter = (allRoutes: string[], routeCmp: string, routeBase: string): boolean => {
  return (compareTaskRoutes(allRoutes, routeCmp, routeBase) >= 0);
};
