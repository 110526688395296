import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Moment } from "moment";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { appColors, appFonts, useAppTheme } from "../../theme";
import { AppButton, NavButton } from "../Buttons";
import { ReactComponent as CalendarBlankLgSvg } from "../../assets/icons/calendar_blank_lg.svg";
import { ReactComponent as EventSvg } from "../../assets/icons/event.svg";
import { ReactComponent as ScheduleSvg } from "../../assets/icons/schedule.svg";
import { ReactComponent as PlaceSvg } from "../../assets/icons/place.svg";
import { ReactComponent as WarningIconColoredSvg } from "../../assets/icons/warning_icon_colored.svg";
import { ReactComponent as CheckCircleFilledColoredSvg } from "../../assets/icons/check_circle_filled_colored.svg";
import { ReactComponent as CaretUpSvg } from "../../assets/icons/caret_up.svg";
import { ReactComponent as CaretDownSvg } from "../../assets/icons/caret_down.svg";
import { formatLanguageDateTime } from "../../data/languageData";
import { formatPhoneNumber, formatWebsiteUrl } from "../../utility/utilityFunctions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { formatAddressLines } from "../../lib/addressUtils";
import { updateUIMessagePopupContext } from "../../features/ui/uiSlice";
import LoadingSpinner from "../LoadingSpinner";
import { determineAppointmentCardAemContent } from "./AppointmentCardAemUtils";
import Aem from "../../lib/aem/components/Aem";


const bgColor: string = "#272829";
const borderColor: string = "#6E7072";

const useStyles = makeStyles({
  card: {
    position: "relative",
    padding: "16px 24px",
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    borderRadius: "8px",
    color: appColors.white,
    textAlign: "left",
  },
  cardOpen: {
  },
  cardCollapsed: {
    padding: "8px 24px",
    "& $cardHeader": {
      marginTop: "4px",
      marginBottom: "4px",
    },
  },
  cardHeader: {
    width: "100%",
    marginTop: "16px",
    marginBottomp: "16px",
  },
  cardHeaderActions: {
    width: "100%",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  cardHeaderDateDetails: {
    marginTop: "16px",
    textAlign: "left",
  },
  cardCollapseButton: {
    color: appColors.white,
    "& .MuiButtonBase-root": {
      color: appColors.white,
    },
    "& svg": {
      fontSize: "14px",
      width: "14px",
      height: "14px",
    },
  },
  cardBody: {
  },
  cardReminder: {
    margin: "0 0 8px 0",
    fontFamily: appFonts.medium,
    fontSize: "16px",
    width: "100%",
  },
  cardReminderTitle: {
    fontFamily: appFonts.bold,
    fontSize: "16px",
  },
  cardReminderMessage: {
    margin: "8px 0 0 0",
    textAlign: "left",
  },
  cardFooter: {
    margin: "0 0 8px 0",
    width: "100%",
  },
  cardFooterActions: {
    width: "100%",
    fontFamily: appFonts.bold,
    fontSize: "14px",
  },
  cardStatusIcon: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
  },
  cardTitle: {
    fontFamily: appFonts.bold,
    fontSize: "18px",
  },
  headerActionsArea: {
  },
  headerActions: {
    padding: "0",
  },
  date: {
    fontFamily: appFonts.bold,
    fontSize: "16px"
  },
  time: {
    fontFamily: appFonts.medium,
    fontSize: "16px"
  },
  location: {
    display: "flex",
    fontFamily: appFonts.medium,
    fontSize: "16px",

    "& *": {
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  locationTitle: {
    fontFamily: appFonts.bold,
    fontSize: "16px",
    marginBottom: "4px",
  },
  locationDetails: {
    "& *": {
      marginBottom: "2px",
    },
  },
  iconWite: {
    "& > svg": {      
      "& > *": {
        fill: appColors.white,
      },
    },
  },
  iconPad: {
    marginTop: "2px",
    marginRight: "10px",
    "& > svg": {      
      width: "16px",
      height: "16px",
    },
  },
  iconStatusConfirmed: {
    "& > path": {
      fill: appColors.success,
    },
  },
  iconStatusCancelled: {
    stroke: appColors.warn,
  },
  hdiv: {
    margin: "16px 0",
    width: "100%",
    height: "1px",
    backgroundColor: borderColor,
  },
  apptActionButton: {
  },
  apptActionButtonLabel: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > *": {
      "&:not(:first-of-type)": {
        marginLeft: "4px",
      },
    },
  },
  spinnerArea: {
    position: "relative",
    width: "100%",
    height: "100%",
  },  
  spinner: {
    "&.MuiCircularProgress-colorPrimary": {
      color: appColors.orangeMid,
  	},
  },
});

interface AppointmentCardProps {
  item?: any;
  confirmed: boolean;
  cancelled: boolean;
  collapsed?: boolean;
  defaultCollapsed?: boolean;
  showReminder?: boolean;
  showHeaderStatus?: boolean;
  showFooterStatus?: boolean;
  showActionButtons?: boolean;
  showCollapseButton?: boolean;
  onConfirmedChange?: (val: boolean) => void;
  onCancelledChange?: (val: boolean) => void;
}

export const AppointmentCard: React.FC<AppointmentCardProps> = (props: AppointmentCardProps) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();

  const { 
    item,
    showReminder,
    showHeaderStatus, 
    showFooterStatus, 
    showActionButtons, 
    showCollapseButton, 
    onConfirmedChange, 
    onCancelledChange,
  } = props;

  const aemOpts = { categoryVisitType: item.aemCategoryVisitType };

  // keep track of the previous props.collapsed value so we can know 
  // when it has changed externally. see useEffect below.
  const prevCollapsed: boolean | undefined = useRef<boolean | undefined>(props.collapsed).current;

  const [confirmed, setConfirmed] = useState<boolean>(!!props.confirmed);
  const [cancelled, setCancelled] = useState<boolean>(!!props.cancelled);
  const [confirmActionPending, setConfirmActionPending] = useState<boolean>(false);
  const [cancelActionPending, setCancelActionPending] = useState<boolean>(false);
  const languageIsoCode: string = useAppSelector<string>((state) => state.preferences.languageIsoCode);
  
  const initCollapsed: boolean = !!(props.collapsed !== undefined? props.collapsed: props.defaultCollapsed);
  const [cardOpen, setCardOpen] = useState<boolean>(!initCollapsed);

  const handleConfirmAppointmentClicked = async () => {
    const titleText: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_25", "Do you want to confirm?", aemOpts);
    const yesText: string = Aem.get("ACTION_YESBUTTON_TEXT_1", "Yes", aemOpts);
    const noText: string = Aem.get("ACTION_NOBUTTON_TEXT_1", "No", aemOpts);

    const handleConfirmPopupClosed = (val:any) => {
      let newConfirmed: boolean = (val === "yes" || val === "ok");
      if (newConfirmed) { 
        if (onConfirmedChange) { 
          setConfirmActionPending(true);
          onConfirmedChange(true);
          localStorage.setItem("categoryVisitType", encodeURIComponent(JSON.stringify(aemOpts))); 
        } else {
          setConfirmed(true);
          setConfirmActionPending(false);
        }
      }
    };

    const popupProps: any = {
      icon: <CalendarBlankLgSvg />,
      title: titleText,
      actions: [
        { value: "yes", title: yesText },
        { value: "no", title: noText },
      ],
      onClose: handleConfirmPopupClosed,
    };
    dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
  };

  const handleCancelAppointmentClicked = async () => {
    const titleText: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_4", "Are you sure you want to cancel?", aemOpts);
    const yesText: string = Aem.get("ACTION_YESBUTTON_TEXT_1", "Yes", aemOpts);
    const noText: string = Aem.get("ACTION_NOBUTTON_TEXT_1", "No", aemOpts);

    const handleCancelPopupClosed = (val:any) => {
      let newCancelled: boolean = (val === "yes" || val === "ok");
      if (newCancelled) { 
        if (onCancelledChange) { 
          setCancelActionPending(true);
          onCancelledChange(true);
        } else {
          setCancelled(true);
          setCancelActionPending(false);
        }
      }
    };

    const popupProps: any = {
      icon: <CalendarBlankLgSvg />,
      title: titleText,
      actions: [
        { value: "yes", title: yesText },
        { value: "no", title: noText },
      ],
      onClose: handleCancelPopupClosed,
    };
    dispatch(updateUIMessagePopupContext({ component: "MessagePopup", props: popupProps }));
  };

  // NOTE: ignoring time zome since API returns all date/times in CDOs local time zone.
  const apptDateVal: Moment | undefined = item.apptDateTime;
  const apptDateStr: string = formatLanguageDateTime(apptDateVal, languageIsoCode, "dddd, MMM D");
  const apptTimeStr: string = formatLanguageDateTime(apptDateVal, languageIsoCode, "h:mma");
  
  const locationName: string = item.location;
  const locationAddressLines: string[] = formatAddressLines(item.deptAddressObj);
  const locationPhone: string = formatPhoneNumber(item.deptPhone);
  const locationWebsiteUrl: string = formatWebsiteUrl(item.deptWebsite);   // NOTE: API does not currently have a website URL

  const cancelBtnLabel: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_10", "Cancel appointment", aemOpts);
  const confirmBtnLabel: string = Aem.get("BODY_MULTI_APPOINTMENTS_TEXT_26", "Confirm", aemOpts);

  const {
    visitTypeTitle,
    reminderTitle,
    reminderMessage,
    showAddress,
    showPhone,
    showWebsiteUrl,
  } = determineAppointmentCardAemContent(item.aemCategoryVisitType, confirmed, cancelled);

  useEffect(() => {
    // watch for collapsed prop value changes
    if (prevCollapsed !== props.collapsed && props.collapsed !== undefined) { 
      setCardOpen(!props.collapsed);
    }
  }, [prevCollapsed, props.collapsed]);

  useEffect(() => {
    // watch status prop value changes
    if (props.confirmed !== confirmed && props.confirmed !== undefined) { 
      setConfirmed(!!props.confirmed);
      setConfirmActionPending(false);
    }
    if (props.cancelled !== cancelled && props.cancelled !== undefined) { 
      setCancelled(!!props.cancelled);
      setCancelActionPending(false);
    }
  }, [confirmed, cancelled, props.confirmed, props.cancelled]);

  return(
    <Grid 
      container 
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"        
      className={clsx(classes.card, (cardOpen ? classes.cardOpen : classes.cardCollapsed) )}
    >
      <Grid container direction="column" className={classes.cardHeader}>
        <Grid item>
          <Grid container direction="row" className={classes.cardHeaderActions}>
            <Grid item className={classes.cardTitle}>
              <Grid container direction="row">
                {showHeaderStatus && confirmed && (
                  <Grid item className={clsx(classes.cardStatusIcon, classes.iconPad)}>
                    <CheckCircleFilledColoredSvg className={classes.iconStatusConfirmed} />
                  </Grid>
                )}
                {showHeaderStatus && cancelled && (
                  <Grid item className={clsx(classes.cardStatusIcon, classes.iconPad)}>
                    <WarningIconColoredSvg className={classes.iconStatusCancelled} />
                  </Grid>
                )}
                <Grid item>
                  {visitTypeTitle}
                </Grid>
              </Grid>
            </Grid>
            {showCollapseButton && (
              <Grid item className={classes.headerActionsArea}>
                <Grid container direction="row">
                  <Grid item className={classes.cardCollapseButton}>
                    <AppButton
                      onClick={() => setCardOpen(!cardOpen)}
                      trackName="appt collapse toggle"
                      trackLocation="body"
                    >
                      {cardOpen? <CaretUpSvg />: <CaretDownSvg />}
                    </AppButton>
                  </Grid>           
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {cardOpen && (
          <Grid 
            container 
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            className={classes.cardHeaderDateDetails}
          >
            <Grid item className={classes.date}>
              <Grid container direction="row">
                <Grid item className={clsx(classes.iconWite, classes.iconPad)}>
                  <EventSvg />
                </Grid>
                <Grid item>
                  {apptDateStr}
                </Grid>
              </Grid>
            </Grid>
            <Grid item className={classes.time}>
              <Grid container direction="row">
                <Grid item className={clsx(classes.iconWite, classes.iconPad)}>
                  <ScheduleSvg />
                </Grid>
                <Grid item>
                  {apptTimeStr}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {cardOpen && (
        <React.Fragment>
          <Grid item className={classes.hdiv}></Grid>
          <Grid item className={classes.cardBody}>
            <Grid 
              container 
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item className={classes.location}>
                <Grid container direction="row">
                  <Grid item className={clsx(classes.iconWite, classes.iconPad)}>
                    <PlaceSvg />
                  </Grid>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item className={classes.locationTitle}>
                        {locationName}
                      </Grid>
                      <Grid item className={classes.locationDetails}>
                        <Grid container direction="column">
                          {showWebsiteUrl && locationWebsiteUrl && (
                            <Grid item>
                              {locationWebsiteUrl}
                            </Grid>
                          )}
                          {showAddress && (
                            <React.Fragment>
                              {locationAddressLines.map((line, idx) => (
                                <Grid key={idx} item>
                                  {line}
                                </Grid>
                              ))}
                            </React.Fragment>
                          )}
                          {showPhone && locationPhone && (
                            <Grid item>
                              {locationPhone}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {showReminder && (
            <React.Fragment>
              <Grid item className={classes.hdiv}></Grid>
              <Grid item className={classes.cardReminder}>
                <Grid 
                  container 
                  direction="column" 
                  justifyContent="start"
                  alignItems="start"
                  className={theme.fillx}>
                  <Grid item className={theme.fillx}>
                    <Grid 
                      container 
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      className={theme.fillx}>
                      <Grid item className={classes.cardReminderTitle}>
                        {reminderTitle}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.cardReminderMessage}>
                    {reminderMessage}
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {(showFooterStatus || showActionButtons) && (
            <React.Fragment>
              <Grid item className={classes.hdiv}></Grid>
              <Grid item className={classes.cardFooter}>
                <Grid container 
                  direction="column" 
                  className={classes.cardFooterActions}
                >
                  {showFooterStatus && confirmed && (
                    <Grid item>
                      <Grid 
                        container
                        direction="row" 
                        justifyContent="flex-start" 
                        alignItems="center"
                      >
                        <Grid item className={clsx(classes.cardStatusIcon, classes.iconPad)}>
                          <CheckCircleFilledColoredSvg className={classes.iconStatusConfirmed} />
                        </Grid>
                        <Grid item>
                          <Aem cid="BODY_MULTI_APPOINTMENTS_TEXT_8" {...aemOpts}>
                            Confirmed
                          </Aem>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {showFooterStatus && cancelled && (
                    <Grid item>
                      <Grid 
                        container
                        direction="row" 
                        justifyContent="flex-start" 
                        alignItems="center"
                      >
                        <Grid item className={clsx(classes.cardStatusIcon, classes.iconPad)}>
                          <WarningIconColoredSvg className={classes.iconStatusCancelled} />
                        </Grid>
                        <Grid item>
                          <Aem cid="BODY_MULTI_APPOINTMENTS_TEXT_9" {...aemOpts}>
                            Cancelled
                          </Aem>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {showActionButtons && !confirmed && !cancelled && (
                    <Grid item>
                      <Grid 
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <NavButton 
                            className={classes.apptActionButton} 
                            size="small"
                            variant="text"
                            fullWidth={false}
                            disabled={confirmActionPending || cancelActionPending}
                            label={(
                              <Grid container className={classes.apptActionButtonLabel}>
                                {confirmActionPending && (<Grid item><LoadingSpinner size={20} accentColor="white" /></Grid>)}
                                <Grid item>{confirmBtnLabel}</Grid>
                              </Grid>
                            )}
                            trackName="confirm appointment"
                            trackLocation="body"
                            onClick={handleConfirmAppointmentClicked}
                          />
                        </Grid>

                        <Grid item>
                          <NavButton 
                            className={classes.apptActionButton} 
                            size="small"
                            variant="text"
                            fullWidth={false}
                            disabled={confirmActionPending || cancelActionPending}
                            label={(
                              <Grid container className={classes.apptActionButtonLabel}>
                                {cancelActionPending && (<Grid item><LoadingSpinner size={20} accentColor="white" /></Grid>)}
                                <Grid item>{cancelBtnLabel}</Grid>
                              </Grid>
                            )}
                            trackName="cancel appointment"
                            trackLocation="body"
                            onClick={handleCancelAppointmentClicked}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};

AppointmentCard.defaultProps = {
  confirmed: false,
  cancelled: false,
  collapsed: undefined,
  defaultCollapsed: false,
  showReminder: false,
  showHeaderStatus: false,
  showFooterStatus: true,
  showActionButtons: false,
  showCollapseButton: false,
};

export default AppointmentCard;

